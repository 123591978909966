import React, { useEffect, useState, useRef } from 'react';
import Preloader from '../../Components/Preloader';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import { NavLink } from 'react-router-dom';
import Home_Seo from '../../SEO/Home_Seo';
import Calculator from '../../Components/Calculator';

function Home() {
  const [isLoading, setIsLoading] = useState(true);
  const [activeIndex, setActiveIndex] = useState(null);
  const contentRefs = useRef([]);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  useEffect(() => {
    // Simulate an asynchronous operation (e.g., fetching data)
    setTimeout(() => {
      setIsLoading(false);
    }, 500); // Simulating a 1-second delay
  }, []);

  return (
    <div>
      {isLoading ? (
        <Preloader />
      ) : (
        <div>
          <Home_Seo />
          <Header />
          {/* Hero Section Here */}
          <div className="th-hero-wrapper hero-4" id="hero">
            <div className="th-hero-bg" data-bg-src="assets/img/bg/hero_bg_4.png" style={{ backgroundImage: 'url(assets/img/bg/hero_bg_4.png)' }} />
            <div className="container">
              <div className="row align-items-center">
                <div className="col-xl-7">
                  <div className="hero-style4">
                    <div className="alert alert-success alert-dismissible fade show">
                      <button type="button" className="btn-close" data-bs-dismiss="alert" />
                      <strong><i className="fas fa-info" /> Dear Visitor,</strong>
                      <br />
                      Our website is currently under construction while we wait for a decision from Financial Conduct Authority on our Small Payment Institution application.
                      We currently do not provide Money remittance service and the rate calculator is not functional.
                    </div>
                    <h1 className="hero-title text-white">
                      International <span className="square-text"><span className="" /> <span className="" />Money Transfer</span>
                    </h1>
                    <p className="hero-text">
                      At eRemitly, we know how vital it is to send money back home for family support, business, or investment. Let’s make your transfer journey seamless and exciting-start with us today!
                    </p>
                    <div className="row justify-content-center mt-2">
                      <div className="item col-lg-3 col-md-3 col-6 p-1">
                        <a className href="/">
                          <div className="flag-div d-flex align-items-center">
                            <div className="flag-mr"><img className="flag" src="assets/img/flags/pkr.png" alt="Money Transfer to Pakistan" /></div>
                            <div className="currency-code">
                              <h4>Pakistan</h4></div>
                          </div>
                        </a>
                      </div>
                      <div className="item col-lg-3 col-md-3 col-6 p-1">
                        <a className href="/">
                          <div className="flag-div d-flex align-items-center">
                            <div className="flag-mr"><img className="flag" src="assets/img/flags/inr.png" alt="Money Transfer to India" /></div>
                            <div className="currency-code">
                              <h4>India</h4></div>
                          </div>
                        </a>
                      </div>
                      <div className="item col-lg-3 col-md-3 col-6 p-1">
                        <a className href="/">
                          <div className="flag-div d-flex align-items-center">
                            <div className="flag-mr"><img className="flag" src="assets/img/flags/lkr.png" alt="Money Transfer to Sri Lanka" /></div>
                            <div className="currency-code">
                              <h4>Sri Lanka</h4></div>
                          </div>
                        </a>
                      </div>
                      <div className="item col-lg-3 col-md-3 col-6 p-1">
                        <a className href="/">
                          <div className="flag-div d-flex align-items-center">
                            <div className="flag-mr"><img className="flag" src="assets/img/flags/npr.png" alt="Money Transfer to Nepal" /></div>
                            <div className="currency-code">
                              <h4>Nepal</h4></div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-5">
                   <Calculator/>
                </div>
              </div>
            </div>
            <div className="shape-mockup z-index-3 spin d-none d-xl-block" data-top="25%" data-left="5%">
              <img src="assets/img/shape/star-16.png" alt />
            </div>
            <div className="shape-mockup spin d-none d-xl-block" data-bottom="16%" data-right="6%">
              <img src="assets/img/shape/star-17.png" alt />
            </div>
          </div>
          {/* Hero Section End */}

          {/*About Section Here */}
          <div className="about-area4" id="about-sec" style={{ backgroundColor: '#f5f8f3' }}>
            <div className="container">
              <div className="row">
                <div className="col-xl-6">
                  <div className="title-area mb-35">
                    <span className="sub-title sub-title2">Why Choose Us</span>
                    <h2 className="sec-title">eRemitly</h2>
                  </div>
                  <p className="mt-n2 mb-25">
                    At eRemitly, we understand the importance of having reliable and effective financial services. Whether you're sending money to family abroad, covering education costs internationally, or handling other cross-border transactions, we make sure your transfers are seamless.
                  </p>
                  <div className="about-feature-wrap">
                    <div className="about-feature style3" style={{ maxWidth: '100%' }}>
                      <div className="box-icon"><img src="assets/img/icon/quick-transfer.png" alt="Icon" /></div>
                      <div className="media-body">
                        <h3 className="box-title">Quick Transfer</h3>
                        <p className="box-text">
                          Send money in just minutes-fast, secure, and reliable every time.
                        </p>
                      </div>
                    </div>
                    <div className="about-feature style3" style={{ maxWidth: '100%' }}>
                      <div className="box-icon"><img src="assets/img/icon/low-cost.png" alt="Icon" /></div>
                      <div className="media-body">
                        <h3 className="box-title">Low Cost</h3>
                        <p className="box-text">
                          Enjoy low fees that let you save more on every transfer.
                        </p>
                      </div>
                    </div>
                    <div className="about-feature style3" style={{ maxWidth: '100%' }}>
                      <div className="box-icon"><img src="assets/img/icon/best-rates.png" alt="Icon" /></div>
                      <div className="media-body">
                        <h3 className="box-title">Best Rates</h3>
                        <p className="box-text">
                          Get the most value with our highly competitive exchange rates, maximizing what you send.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="btn-group mt-35">
                    <NavLink target="_blank" to="https://play.google.com/">
                      <img src="assets/img/theme-img/play-store-btn.png" alt="img" />
                    </NavLink>
                    <NavLink target="_blank" to="https://www.apple.com/store">
                      <img src="assets/img/theme-img/apple-btn.png" alt="img" />
                    </NavLink>
                  </div>
                </div>
                <div className="col-xl-6 mt-30 mt-xl-0">
                  <div className="img-box8">
                    <div className="img1">
                      <img src="assets/img/normal/cta-img5.jpg" alt="About" />
                      <div className="about-shape" />
                    </div>
                    {/* <div className="img2 jump"><img src="assets/img/normal/about_6_2.png" alt="About" /></div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* About Section Here */}

          {/* Steps Section Here */}
          <div className="counter-area4 position-relative" data-bg-src="assets/img/bg/counter_bg_1.png" style={{ backgroundImage: 'url(assets/img/bg/counter_bg_1.png)' }}>
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-xl-9">
                  <div className="title-area text-center pe-xl-3 ps-xl-3">
                    <span className="sub-title sub-title2 text-white">How We Work</span>
                    <h2 className="sec-title text-white">You Can Send Money With The Following Four Easy Steps</h2>
                  </div>
                </div>
              </div>
              <div className="row gy-5">
                <div className="col-xl-3 col-md-6 service-featured_wrapp">
                  <div className="service-featured">
                    <div className="box-icon" >
                      <img src="assets/img/icon/customer.png" alt="Icon" style={{ maxWidth: '70%' }} /> <span className="box-shape"></span></div>
                    <h3 className="box-title text-white"><a href="/">1. Create An Account</a></h3>
                    <p className="box-text text-white">
                      Download our app and get signed up in just minutes. It’s quick and completely free!
                    </p>
                  </div>
                </div>
                <div className="col-xl-3 col-md-6 service-featured_wrapp">
                  <div className="service-featured">
                    <div className="box-icon">
                      <img src="assets/img/icon/transfer.png" alt="Icon" style={{ maxWidth: '70%' }} /> <span className="box-shape"></span></div>
                    <h3 className="box-title text-white"><a href="/">2. Set up a transfer</a></h3>
                    <p className="box-text text-white">
                      Add your recipient and specify the amount you wish to send or receive in the foreign currency.
                    </p>
                  </div>
                </div>
                <div className="col-xl-3 col-md-6 service-featured_wrapp">
                  <div className="service-featured">
                    <div className="box-icon">
                      <img src="assets/img/icon/securely.png" alt="Icon" style={{ maxWidth: '70%' }} /> <span className="box-shape"></span></div>
                    <h3 className="box-title text-white"><a href="/">3. Pay securely</a></h3>
                    <p className="box-text text-white">
                      Complete your payment via Bank Transfer—it's the safest and fastest method to ensure your funds are received.
                    </p>
                  </div>
                </div>
                <div className="col-xl-3 col-md-6 service-featured_wrapp">
                  <div className="service-featured">
                    <div className="box-icon">
                      <img src="assets/img/icon/done.png" alt="Icon" style={{ maxWidth: '70%' }} /> <span className="box-shape"></span></div>
                    <h3 className="box-title text-white"><a href="/">4. That's it!</a></h3>
                    <p className="box-text text-white">Track your transfer from the app. We payout to beneficiary as promised in delivery method.</p>
                  </div>
                </div>
              </div>
              <div className="brand-slider mt-5">
                <div className="row align-items-center justify-content-between">
                  <div className="col-xl-10">
                    <div className="brand-title-area text-center text-xl-start">
                      <div className="title-area mb-0 pe-xl-2">
                        <h3 className="sec-title text-white">Ready to get started?</h3>
                        <p className="brand-text text-white mb-n2 m-0">
                          Let your journey begin with us
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-2 text-center">
                    <NavLink to="/" className="th-btn style7 mt-4">Sign up Now</NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Steps Section End */}

          {/* Mobile App Section Start */}
          <div className="about-area4 bg-mask" id="about-sec" style={{ maskImage: 'url("assets/img/bg/testi_bg_shape.png")' }}>
            <div className="container">
              <div className="row">
                <div className="col-xl-6">
                  <div className="title-area mb-35"><span className="sub-title sub-title2">Download our app</span>
                    <h2 className="sec-title">Save time with the eRemitly app</h2></div>
                  <p className="mt-n2 mb-25">
                    Start your money transfer journey with the eRemitly app. Download from the Play Store or App Store and enjoy exclusive benefits, including:
                  </p>
                  <div className="checklist style3 mb-35">
                    <ul>
                      <li><i className="fa-solid fa-circle-check" />Easy sign up</li>
                      <li><i className="fa-solid fa-circle-check" />Save time with Repeat Transfer</li>
                      <li><i className="fa-solid fa-circle-check" />Track your payments in real time</li>
                      <li><i className="fa-solid fa-circle-check" />Pay using your favourite Payment Method</li>
                    </ul>
                  </div>
                  <h6><em>Make your global money transfers smoother with eRemitly.</em></h6>
                  <div className="btn-group mt-35">
                    <NavLink target="_blank" to="https://play.google.com/">
                      <img src="assets/img/theme-img/play-store-btn.png" alt="img" />
                    </NavLink>
                    <NavLink target="_blank" to="https://www.apple.com/store">
                      <img src="assets/img/theme-img/apple-btn.png" alt="img" />
                    </NavLink>
                  </div>
                </div>
                <div className="col-xl-6 mt-30 mt-xl-0">
                  <div className="img-box8">
                    <div className="img1">
                      <img src="assets/img/normal/download-2-1.png" alt="Mobile App" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Mobile App Section End */}

          {/* FAQs Section Here */}
          <div className="overflow-hidden space" id="faq-sec">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-6">
                  <div className="title-area text-center pe-xl-3 ps-xl-3">
                    <span className="sub-title sub-title2">Our FAQ'S</span>
                    <h2 className="sec-title">Frequently Ask Questions</h2>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-lg-12">
                  <div className="accordion-area accordion" id="faqAccordion">
                    <div className="accordion-card style3">
                      <div className="accordion-header" id="collapse-item-1">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-1" aria-expanded="false" aria-controls="collapse-1">
                          Why do you need my documents while registration?
                        </button>
                      </div>
                      <div id="collapse-1" className="accordion-collapse collapse" aria-labelledby="collapse-item-1" data-bs-parent="#faqAccordion" style={{}}>
                        <div className="accordion-body">
                          <p className="faq-text">
                            We are regulated by Financial Conduct Authority in the UK. It is our legal binding to verify your identity as a UK resident.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-card style3">
                      <div className="accordion-header" id="collapse-item-2">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-2" aria-expanded="false" aria-controls="collapse-2">
                          How can I cancel my transfer?
                        </button>
                      </div>
                      <div id="collapse-2" className="accordion-collapse collapse" aria-labelledby="collapse-item-2" data-bs-parent="#faqAccordion" style={{}}>
                        <div className="accordion-body">
                          <p className="faq-text">
                            Please call us or email us at earliest possible if you want to cancel your transfer. Transfer paid out to the recipient cannot be cancelled. If you have paid for your transfer and the transfer is not processed yet, then please contact us by phone and we will make necessary arrangements for refunds. We may charge you a small fee as handling/service charge and refund you the balance amount.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-card style3">
                      <div className="accordion-header" id="collapse-item-3">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-3" aria-expanded="false" aria-controls="collapse-3">
                          Will I get notifications about Transfer status?
                        </button>
                      </div>
                      <div id="collapse-3" className="accordion-collapse collapse" aria-labelledby="collapse-item-3" data-bs-parent="#faqAccordion">
                        <div className="accordion-body">
                          <p className="faq-text">
                            Yes. We send email notifications to keep you informed. You can even view the transfer status by signing in into your web account.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-card style3">
                      <div className="accordion-header" id="collapse-item-4">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-4" aria-expanded="false" aria-controls="collapse-4">
                          How many recipients I can add?
                        </button>
                      </div>
                      <div id="collapse-4" className="accordion-collapse collapse" aria-labelledby="collapse-item-4" data-bs-parent="#faqAccordion">
                        <div className="accordion-body">
                          <p className="faq-text">
                            There is no restriction on the number of recipients you may have on your account.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-card style3">
                      <div className="accordion-header" id="collapse-item-5">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-5" aria-expanded="false" aria-controls="collapse-5">
                          You asked me to upload one more document; how can I do that?
                        </button>
                      </div>
                      <div id="collapse-5" className="accordion-collapse collapse" aria-labelledby="collapse-item-5" data-bs-parent="#faqAccordion">
                        <div className="accordion-body">
                          <p className="faq-text">
                            Please login in to your account and under My Account you will find document section where you can upload the document.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-card style3">
                      <div className="accordion-header" id="collapse-item-6">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-6" aria-expanded="false" aria-controls="collapse-6">
                          How can I pay for my transfer?
                        </button>
                      </div>
                      <div id="collapse-6" className="accordion-collapse collapse" aria-labelledby="collapse-item-6" data-bs-parent="#faqAccordion">
                        <div className="accordion-body">
                          <p className="faq-text">
                            Bank Transfer to our business account and payments made by using credit or debit cards. Bank Transfer is the recommended and preferred method of payment. There may be restrictions on credit and debit card payment as well as there may be a fee charged on top.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='text-center'>
                <NavLink to="/generalfaq" className="th-btn style7 mt-4">View more</NavLink>
              </div>
            </div>
          </div>

          {/* FAQs Section End */}

          {/* Footer Section Here */}
          <Footer />
        </div>
      )}
    </div>
  );
}

export default Home;
